import axios from 'axios';

const auth = {
    namespaced: true,
    state: () => ({
        token: null,
        employee: null,
        loading: false,
    }),
    mutations: {
        token: (state, token) => {
            state.token = token
        },
        loading: (state, loading) => {
            state.loading = !!loading
        },
        employee: (state, employee) => {
            state.employee = employee

            // Проверяем наличие employee и темы
            let colors = _.get(employee, 'theme.colors', false);
            if (colors) {
                // Сохраняем тему в localStorage
                localStorage.setItem('user.colors', JSON.stringify(colors));
            }
        },
    },
    actions: {
        load: ({ commit }) => {
            commit('token', localStorage.getItem('token'));
            axios.defaults.headers.common['Authorization'] = ` Bearer ${localStorage.getItem('token')}`;
        },
        load_from: ({ commit }, data) => {
            if (data && data.token) {
                commit('token', data.token);
                axios.defaults.headers.common['Authorization'] = ` Bearer ${data.token}`;
                localStorage.setItem('token', data.token);
            }
        },
        unload: ({ commit }) => {
            commit('token', null);
            localStorage.removeItem('token');
            axios.defaults.headers.common['Authorization'] = ` `;
        },
        logout: ({ commit, dispatch }) => {
            axios.post(`/logout`).then(resp => {
            }).catch(err => {
            }).finally(() => {
                dispatch('unload');
            });
        },
        login: ({ dispatch, commit }, data) => {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                axios.post('login', {
                    ...data
                })
                    .then(resp => {
                        dispatch('load_from', resp.data)
                        commit('loading', false)
                        dispatch('employee')
                        resolve(resp)
                    })
                    .catch(err => {
                        dispatch('unload')
                        commit('loading', false)
                        reject(err)
                    })
            })
        },
        employee({ commit }) {
            axios.get(`employee`)
                .then(response => {
                    commit('employee', response.data);
                })
                .catch(error => {

                });
        },
    }
};

export default auth;
