import axios from 'axios';

const app = {
    namespaced: true,
    state: () => ({
        drawler: false,
        menu: [],
        module: null,
        last_error: null,
        badges: {},
    }),
    mutations: {

        drawler(state, drawler) {
            state.drawler = drawler;
            localStorage.setItem('app.drawler', drawler ? 1 : '');
        },
        last_error(state, last_error) {
            state.last_error = last_error;
        },
        module(state, module) {
            state.module = module;
        },

        badges(state, badges) {
            state.badges = badges;

        },
        menu(state, menu) {
            state.menu = menu;

        },
    },

    actions: {
        menu({ commit, state }) {
            return new Promise((resolve, reject) => {
                commit('last_error', null);
                axios.get(`/system/menu`)
                    .then(resp => {
                        commit('menu', resp.data.items);
                        resolve(state.drawler);
                    })
                    .catch(err => commit('last_error', err));
            });
        },
        badges({ commit, state }) {
            commit('last_error', null);
            axios.get(`/system/badges`)
                .then(resp => {
                    //Пройтись по циклу drawler и заполнить badges
                    commit('badges', resp.data);
                })
                .catch(err => commit('last_error', err));
        },
        badge({ commit, state }, slug) {
            commit('last_error', null);
            axios.get(`/system/badges/${slug}`)
                .then(resp => commit('badge', resp.data.item))
                .catch(err => commit('last_error', err));
        },
    }
};


export default app;
