<template>
  <v-app>
    <v-main>
      <v-container class="h-100">
        <v-row class="align-center justify-center h-100">
          <v-col cols="12" sm="8" md="4">
            <v-card :elevation="0">
              <v-toolbar flat dense>
                <v-toolbar-title dense>Вход</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="formValid">
                  <v-text-field hide-details="auto" v-model="data.login" name="email" label="E-mail" type="email"
                    :rules="[rules.required, rules.email]" autocomplete="email" />
                  <v-text-field hide-details="auto" v-model="data.password" name="password" label="Пароль"
                    type="password" :rules="[rules.required]" autocomplete="current-password" />
                </v-form>
                <v-alert close-ext="Close Alert" color="error" icon="priority_high" dismissible
                  v-model="auth_error.value" class="mt-5 mb-0">{{ auth_error.text }}</v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" depressed text @click.prevent="onSubmit">Войти</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { getMsgError, rules, useRouter, useStore } from "@utils";
import { computed, ref } from "vue";
export default {
  name: "Login",
  props: {
    source: String,
  },


  setup(props, ctx) {
    const form = ref(null);
    const formValid = ref(true);
    const store = useStore();
    const router = useRouter();
    const loading = computed(() => store.state.auth.loading);
    const auth_error = ref({
      text: null,
      value: false,
    });
    const data = ref({
      login: null,
      password: null,
    });
    const onSubmit = (e) => {
      form.value.validate();
      if (formValid.value) {
        auth_error.value.text = null;
        auth_error.value.value = false;
        store
          .dispatch("auth/login", data.value)
          .then(() => {
            router.push("/");
          })
          .catch((err) => {
            auth_error.value.text = getMsgError(err);
            auth_error.value.value = true;
          });
      }
      return false;
    };
    return {
      form,
      onSubmit,
      rules,
      formValid,
      data,
      loading,
      auth_error,
    };
  },
};
</script>
