import store from "./create";

import { registerModulesContent } from "../ui/content/store";
import { registerModulesFundraising } from "../ui/fundraising/store";
import app from "./modules/app";
import auth from "./modules/auth";
import badges from "./modules/badges";
import config from "./modules/config";


store.registerModule("app", app);
store.registerModule("auth", auth);
store.registerModule("config", config);
store.registerModule("badges", badges);

registerModulesFundraising(store);
registerModulesContent(store);

export default store;