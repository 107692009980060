import axios from 'axios';
import router from '../../router';

const model = {
    namespaced: true,
    state: () => ({
        loading: false,
        page: 1,
        total: null,
        api: null,
        current: null,
        query: null,
        options: null,
        errors: null,
        type: null,
        headers: null,
        markup: null,
        name: null,
    }),
    mutations: {
        name(state, name) {
            state.name = name;
        },
        type(state, type) {
            state.type = type;
        },
        headers(state, headers) {
            state.headers = headers;
        },

        refresh(state) { },
        page(state, page) {
            state.page = page;
        },
        markup(state, markup) {
            state.markup = markup;
        },
        loading(state, loading) {
            state.loading = loading;
        },
        fetch(state, modelArr, page) {
            state.items = modelArr;
            state.page = page;
        },
        total(state, total) {
            state.total = total ? total : null;
        },
        check(state) {
        },
        api(state, api) {
            state.api = api;
        },
        current(state, current) {
            state.current = current;
        },
        query(state, query) {
            state.query = query;
        },
        options(state, options) {
            state.options = options;
        }
    },

    actions: {
        markup({ state, commit }) {
            axios.get(`/json_markups/label/${state.api}`).then(resp => {
                commit('markup', resp.data.item);
            }).catch(err => toast.error(getMsgError(err)));
        },
        approve({ commit, state }, item) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                axios.get(`${state.api}/approve/${item.id}`, {
                    params: {
                        'status': item.status,
                    }
                })
                    .then(response => {
                        commit('loading', false);
                        resolve(response);
                    })
                    .catch(error => {
                        commit('loading', false);
                        reject(error)
                    });
            })
        },
        headers({ state, commit }) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                if (state.headers) return state.headers;
                axios
                    .get(`/system/data_headers/${state.name}`)
                    .then(resp => {
                        commit('headers', JSON.parse(JSON.stringify(resp.data.items)));
                        commit('loading', false);
                        resolve(resp.data)
                    })
                    .catch(error => {
                        commit('loading', false);
                        reject(error)
                    })
            })
        },
        options({ state, commit }) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                if (state.options) return state.options;
                axios
                    .get(`${state.api}/options`)
                    .then(resp => {
                        commit('options', JSON.parse(JSON.stringify(resp.data)));
                        commit('loading', false);
                        resolve(resp.data)
                    })
                    .catch(error => {
                        commit('loading', false);
                        reject(error)
                    })
            })
        },
        fetch({ state, commit }) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${state.api}`,
                        {
                            //
                            params: Object.assign({}, router.currentRoute.query)
                        }
                    )
                    .then(response => {
                        commit('current', null);
                        commit('total', response.data.total);
                        commit('loading', false);
                        resolve(response.data);
                    })
                    .catch(error => {
                        commit('loading', false);
                        reject(error)
                    })
            })
        },
        show({ state, commit }, id) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${state.api}/${id}`)
                    .then(resp => {
                        commit('current', JSON.parse(JSON.stringify(resp.data.item)));
                        commit('loading', false);
                        resolve(resp.data)
                    })
                    .catch(error => {
                        commit('loading', false);
                        reject(error)
                    })
            })
        },
        store({ state, commit }, item) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                axios
                    .post(`${state.api}`, {
                        ...item
                    })
                    .then(response => {
                        commit('loading', false);
                        resolve(response.data)
                    }).catch(error => {
                        commit('loading', false);
                        reject(error)
                    })
            })
        },
        create({ state, commit }) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                axios
                    .get(`${state.api}/create`)
                    .then(response => {
                        commit('loading', false);
                        resolve(response.data)
                    }).catch(error => {
                        commit('loading', false);
                        reject(error)
                    })
            })
        },
        update({ state, commit }, item) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                axios
                    .put(`${state.api}/${item.id}`, item)
                    .then(response => {
                        commit('loading', false);
                        if (response.data && response.data.item) {
                            commit('current', JSON.parse(JSON.stringify(response.data.item)));
                        }
                        resolve(response.data)
                    }).catch(error => {
                        commit('loading', false);
                        reject(error)
                    })
            })
        },
        destroy({ state, commit }, id) {
            commit('loading', true);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`${state.api}/${id}`)
                    .then(response => {
                        commit('loading', false);
                        resolve(response.data)
                    }).catch(error => {
                        commit('loading', false);
                        reject(error)
                    })
            })
        },
    }
};


export default model;
