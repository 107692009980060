import model from "../../../store/modules/model";

const modules = [
    "templates",
    "posts",
    "meta_tags",
    "post_types",
    "text_blocks",
    "text_block_types",
    "json_markups",
    "redirects",
    "employees",
    "leads",
    "menus",
    "notifications",
    "tags",
    "comments",
    //
];
export function registerModulesContent(store) {
    modules.forEach((val) => {
        if (!store.hasModule(val)) {
            store.registerModule(val, model);
        }
        store.commit(`${val}/api`, val);
        store.commit(`${val}/name`, val);
    });
}
export default registerModulesContent;
