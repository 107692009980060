export default [
    {
        path: 'text_blocks',
        props: true,
        component: () => import('../components/Enter.vue'),
        name: 'content.text_blocks.dashboard',
    },


    {
        path: 'text_blocks/:label',
        component: () => import('../components/Index.vue'),
        props: true,
        children: [
            {
                path: '',
                name: 'content.text_blocks.list',
                component: () => import('../components/List.vue'),
                props: true,
            },

            {

                path: ':id(\\d+)',
                props: true,
                component: () => import('../components/Item.vue'),
                children: [
                    {
                        path: 'delete',
                        name: 'content.text_blocks.delete',
                        props: true,
                        component: () => import('../components/Delete.vue'),
                    },
                    {
                        path: 'show',
                        name: 'content.text_blocks.show',
                        props: true,
                        component: () => import('../components/Show.vue'),
                    },
                    {
                        path: 'update',
                        name: 'content.text_blocks.update',
                        component: () => import('../components/Update.vue'),
                        props: true,
                    },
                ]
            },
        ]
    },




];
