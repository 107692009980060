import json_markups from './json_markups';
import post_types from './post_types';
import templates from './templates';
import text_block_types from './text_block_types';

export default [

    {
        path: 'system',
        component: () => import('../components/Index.vue'),
        name: 'settings',
        children: [
            {
                path: 'menu',
                props: true,
                name: 'settings.menu',
                component: () => import('../components/menus/Index.vue'),
            },

            {
                path: 'variables',
                component: () => import('../components/Variables.vue'),
                props: true,
                name: 'settings.variables',
            },
            {
                path: 'redirects',
                name: 'settings.redirects',
                props: true,
                component: () => import('../components/Redirects.vue'),
            },
            ...templates,
            ...json_markups,
            ...post_types,
            ...text_block_types,
        ]
    },

];
