import Vue from 'vue'
import VueRouter from 'vue-router'
import cabinet from '../ui/cabinet/routes/index'
import content from '../ui/content/routes/index'
import dashboard from '../ui/dashboard/routes/index'
import employees from '../ui/employees/routes/index'
import errors from '../ui/errors/routes/index'
import fundraising from '../ui/fundraising/routes/index'
import settings from '../ui/settings/routes/index'
import formProcessor from '../ui/form-processor/routes/index'

import leads from './routes/leads'

Vue.use(VueRouter);
cabinet.children.push(
    ...dashboard,
    content,
    ...settings,
    ...leads,
    ...employees,
    ...fundraising,
    ...formProcessor,
);
const routes = [
    cabinet,
    ...errors,
];
const router = new VueRouter({
    mode: 'history',
    base: '/',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes,

})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}


export default router
