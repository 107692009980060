const gateways = {
  path: "gateways",
  name: "fundraising.settings.gateways",
  component: () =>
    import("/resources/js/ui/fundraising/components/settings/gateways/Index.vue"),
  props: true,
  children: [
    {
      path: "list",
      name: "fundraising.settings.gateways.list",
      component: () =>
        import(
          "/resources/js/ui/fundraising/components/settings/gateways/List.vue"
        ),
      props: true,
    },
    {
      path: ":id",
      props: true,
      name: "fundraising.settings.gateways.item",
      component: () =>
        import(
          "/resources/js/ui/fundraising/components/settings/gateways/item/Index.vue"
        ),
      children: [
        {
          path: "info",
          name: "fundraising.settings.gateways.item.info",
          props: true,
          component: () =>
            import(
              "/resources/js/ui/fundraising/components/settings/gateways/item/Info.vue"
            ),
        },
        {
          path: "payments",
          name: "fundraising.settings.gateways.item.payments",
          props: true,
          component: () =>
            import(
              "/resources/js/ui/fundraising/components/settings/gateways/item/Payments.vue"
            ),
        },
        {
          path: "points",
          name: "fundraising.settings.gateways.item.points",
          props: true,
          component: () =>
            import(
              "/resources/js/ui/fundraising/components/settings/gateways/item/Points.vue"
            ),
        },

        {
          path: "donors",
          name: "fundraising.settings.gateways.item.donors",
          props: true,
          component: () =>
            import(
              "/resources/js/ui/fundraising/components/settings/gateways/item/Donors.vue"
            ),
        },
        {
          path: "delete",
          name: "fundraising.settings.gateways.delete",
          props: true,
          component: () =>
            import(
              "/resources/js/ui/fundraising/components/settings/gateways/Delete.vue"
            ),
        },
      ],
    },
  ],
};
export default gateways;
