<template>
  <div>
    <p v-if="title">{{ title }}</p>
    <v-row>
      <v-col cols="12">
        <improve-text-dialog :original-text="localValue" class="ml-auto mb-1" @save="saveText" />
        <div class="editor-container mb-5" :style="{ height: height }">
          <CKEditor v-model="localValue" :editor="Editor" :config="editorConfig" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ImproveTextDialog from './ImproveTextDialog.vue';

import { useRoute, useStore, useToast } from "@utils";
import {
  computed,
  onMounted,
  ref,
  watch
} from "vue";
export default {
  components: {
    CKEditor: CKEditor.component,
    ImproveTextDialog,
  },
  props: {
    value: {
      default: "",
    },
    title: {
      default: "",
    },
    submitted: {
      default: false,
    },

    height: {
      default: "200px",
    },
  },

  setup(props, { emit }) {
    const localValue = ref("");
    const toast = useToast();
    const route = useRoute();
    const store = useStore();
    const propsValue = computed(() => props.value);
    const Editor = computed(() => window.ClassicEditor);
    const editorConfig = ref({
      charset: "utf-8",
      language: {
        ui: "ru",
        content: "ru",
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      list: {
        properties: {
          styles: true,
          startIndex: true,
          reversed: true,
        },
      },
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true,
            startIndex: false,
          },
        ],
      },
      image: {
        styles: ["alignCenter", "alignLeft", "alignRight"],
        resizeOptions: [
          {
            name: "resizeImage:original",
            label: "Original",
            value: null,
          },
          {
            name: "resizeImage:25",
            label: "25%",
            value: "25",
          },
          {
            name: "resizeImage:50",
            label: "50%",
            value: "50",
          },
          {
            name: "resizeImage:75",
            label: "75%",
            value: "75",
          },
        ],
        toolbar: [
          "imageTextAlternative",
          "toggleImageCaption",
          "|",
          "imageStyle:inline",
          "imageStyle:wrapText",
          "imageStyle:breakText",
          "imageStyle:side",
          "|",
          "resizeImage",
        ],
        insert: {
          integrations: ["insertImageViaUrl"],
        },
      },
      mediaEmbed: {
        toolbar: ['mediaEmbed'],
        previewsInData: true,
        providers: [
          {
            name: 'youtube',
            url: [
              /^(?:m\.)?youtube\.com\/watch\?v=([\w-]+)/,
              /^(?:m\.)?youtube\.com\/v\/([\w-]+)/,
              /^youtube\.com\/embed\/([\w-]+)/,
              /^youtu\.be\/([\w-]+)/
            ],
            html: match => `<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
                    <iframe src="https://www.youtube.com/embed/${match[1]}"
                    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
                    frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
                    </iframe>
                </div>`
          },
          {
            name: 'vk',
            url: [
              /^(?:vk\.com\/video(?:_ext\.php\?oid=)?|vk\.ru\/video\/?)(?:(?:.*?[?&]v=|\/)([\w-]+)(?:.*?[?&]list=([\w-]+))?)/,
              /^(?:vk\.com\/video|vk\.ru\/video\/)(-?\d+)_(\d+)/
            ],
            html: match => {
              const videoId = match[1] && match[2] ? `${match[1]}_${match[2]}` : match[1];
              return `<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
                    <iframe src="https://vk.com/video_ext.php?oid=${match[1]}&id=${match[2]}&hd=1"
                    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
                    frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
                    </iframe>
                </div>`;
            }
          },
          {
            name: 'dzen',
            url: [
              /^dzen\.ru\/video\/watch\/([a-zA-Z0-9-]+)/,
              /^zen\.yandex\.ru\/video\/watch\/([a-zA-Z0-9-]+)/
            ],
            html: match => `<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
                    <iframe src="https://dzen.ru/embed/video/${match[1]}"
                    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
                    frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>
                    </iframe>
                </div>`
          },
          {
            name: 'rutube',
            url: [
              /^rutube\.ru\/video\/([a-zA-Z0-9]+)/,
              /^rutube\.ru\/play\/embed\/([a-zA-Z0-9]+)/
            ],
            html: match => `<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
                    <iframe src="https://rutube.ru/play/embed/${match[1]}"
                    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
                    frameborder="0" allow="autoplay; encrypted-media" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                    </iframe>
                </div>`
          },
          {
            name: 'vimeo',
            url: [
              /^vimeo\.com\/(\d+)/,
              /^vimeo\.com\/[^/]+\/[^/]+\/video\/(\d+)/,
              /^vimeo\.com\/album\/[^/]+\/video\/(\d+)/,
              /^vimeo\.com\/channels\/[^/]+\/(\d+)/,
              /^vimeo\.com\/groups\/[^/]+\/videos\/(\d+)/,
              /^vimeo\.com\/ondemand\/[^/]+\/(\d+)/,
              /^player\.vimeo\.com\/video\/(\d+)/
            ],
            html: match => `<div style="position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;">
                    <iframe src="https://player.vimeo.com/video/${match[1]}"
                    style="position: absolute; width: 100%; height: 100%; top: 0; left: 0;"
                    frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen>
                    </iframe>
                </div>`
          }
        ]
      },
      toolbar: {
        shouldNotGroupWhenFull: true,
        withText: true,
        items: [
          "heading",
          "style",
          "sourceEditing",
          "alignment",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "bulletedList",
          "numberedList",
          "|",
          "link",
          "imageInsert",
          "mediaEmbed",
          "blockQuote",
          "removeFormat",
        ],
      },
      htmlEmbed: {
        showPreviews: true,
      },
      simpleUpload: {
        withCredentials: true,
        uploadUrl: `/api/upload/post/${route.params.id}`,
        headers: {
          "X-CSRF-TOKEN": "CSRF-Token",
          Authorization: `Bearer ${store.state.auth.token}`,
        },
      },
    });
    const saveText = (text) => {
      localValue.value = text;
      emit("input", text);
    };
    watch(propsValue, (n, p) => {
      if (n && n !== localValue.value) {
        localValue.value = n;
      }
    });
    watch(localValue, (n, p) => {
      if (n && n !== p) {
        if (n !== propsValue.value) {
          emit("input", n);
        }
      } else {
        emit("input", "");
      }
    });

    onMounted(() => {
      localValue.value = props.value ? props.value : "";
    });
    return {
      editorConfig,
      localValue,
      Editor, saveText
    };
  },
};
</script>
