import axios from "axios";
import _ from "lodash";
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VCurrencyField from "v-currency-field";
import Vue from "vue";
import VueToast from "vue-toastification";
import Vuetify from "vuetify";

import App from "./App.vue";
import "./components/share/ckeditor/build/ckeditor";
import "./moment";
import router from "./router";
import store from "./store";
window._ = _;

axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.baseURL = "/api/";
window.axios = axios;

import { VueMasonryPlugin } from "vue-masonry";
import VueMasonry from "vue-masonry-css";
import AutocompleteApiInput from "/resources/js/components/share/inputs/AutocompleteApiInput.vue";
import AutocompleteInput from "/resources/js/components/share/inputs/AutocompleteInput.vue";
import DateTimeInput from "/resources/js/components/share/inputs/DateTimeInput.vue";
import ImageUpload from "/resources/js/components/share/inputs/ImageUpload.vue";


import FileTypeIcon from "/resources/js/components/share/FileTypeIcon.vue";
import PostEditor from "/resources/js/components/share/PostEditor.vue";

import ru from "vuetify/src/locale/ru";
Vue.component("AutocompleteInput", AutocompleteInput);
Vue.component("AutocompleteApiInput", AutocompleteApiInput);
Vue.use(VueMasonryPlugin);

Vue.component("ImageUpload", ImageUpload);
Vue.component("FileTypeIcon", FileTypeIcon);
Vue.component("PostEditor", PostEditor);
Vue.component("DateTimeInput", DateTimeInput);
Vue.use(VCurrencyField, {
  locale: "ru-RU",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});
Vue.use(VueMasonry);
Vue.use(Vuetify);
Vue.use(VueToast, {
  timeout: 2000,
  position: "bottom-right",
  maxToasts: 6,
  hideProgressBar: true,
});

let colors = JSON.parse(localStorage.getItem('user.colors'));
if (colors && typeof colors === 'object' && colors.hasOwnProperty('primary')) {

} else {
  colors = {
    "error": "#FF3B30",
    "info": "#4A4A4A",
    "primary": "#2C2C2C",
    "secondary": "#1A1A1A",
    "success": "#4CAF50",
    "warning": "#FF9F0A",
    "panel": "#E8F1F1"
  };
}
const opts = {
  lang: {
    locales: { ru },
    current: "ru",
  },
  icons: {
    iconfont: 'md',
  },
  font: {
    family: 'Inter',
  },
  theme: {
    themes: {
      light: { ...colors },
    },
  },
};
const app = new Vue({
  el: "#app",
  router,
  store,
  vuetify: new Vuetify(opts),
  render: (h) => h(App),
});
