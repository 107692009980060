const campaigns = {
  path: "campaigns",
  name: "fundraising.campaigns",
  component: () =>
    import("/resources/js/ui/fundraising/components/campaigns/Index.vue"),
  props: true,
  children: [
    {
      path: "list",
      name: "fundraising.campaigns.list",
      component: () =>
        import("/resources/js/ui/fundraising/components/campaigns/List.vue"),
      props: true,
    },
    {
      path: ":id",
      props: true,
      component: () =>
        import("/resources/js/ui/fundraising/components/campaigns/Item/Index.vue"),
      children: [
        {
          path: "delete",
          name: "fundraising.campaigns.delete",
          props: true,
          component: () =>
            import("/resources/js/ui/fundraising/components/campaigns/Delete.vue"),
        },
        {
          path: "update",
          name: "fundraising.campaigns.update",
          props: true,
          component: () =>
            import("/resources/js/ui/fundraising/components/campaigns/Update.vue"),
        },
        {
          path: "info",
          name: "fundraising.campaigns.info",
          props: true,
          component: () =>
            import("/resources/js/ui/fundraising/components/campaigns/Item/Info/Index.vue"),

        },
        {
          path: "payments",
          name: "fundraising.campaigns.payments",
          props: true,
          component: () =>
            import("/resources/js/ui/fundraising/components/campaigns/Item/Payments/Index.vue"),
        },
        {
          path: "widgets",
          name: "fundraising.campaigns.widgets",
          props: true,
          component: () =>
            import("/resources/js/ui/fundraising/components/campaigns/Item/Widgets/Index.vue"),
          children: [
            {
              path: "list",
              name: "fundraising.campaigns.widgets.list",
              props: true,
              component: () =>
                import("/resources/js/ui/fundraising/components/campaigns/Item/Widgets/List.vue"),
            },
            {
              path: "create",
              name: "fundraising.campaigns.widgets.create",
              props: true,
              component: () =>
                import("/resources/js/ui/fundraising/components/campaigns/Item/Widgets/Create.vue"),
            },
            {
              path: ":widget_id",
              name: "fundraising.campaigns.widgets.update",
              props: true,
              component: () =>
                import("/resources/js/ui/fundraising/components/campaigns/Item/Widgets/Update.vue"),
            },
            {
              path: ":widget_id/delete",
              name: "fundraising.campaigns.widgets.delete",
              props: true,
              component: () =>
                import("/resources/js/ui/fundraising/components/campaigns/Item/Widgets/Delete.vue"),
            },
            {
              path: ":widget_id/:method_id",
              name: "fundraising.campaigns.widgets.params",
              props: true,
              component: () =>
                import(
                  "/resources/js/ui/fundraising/components/campaigns/Item/Widgets/Params/Index.vue"
                ),
              children: [
                {
                  path: "docs",
                  name: "fundraising.campaigns.widgets.params.docs",
                  props: true,
                  component: () =>
                    import(
                      "/resources/js/ui/fundraising/components/campaigns/Item/Widgets/Params/Docs/Index.vue"
                    ),
                },
                {
                  path: "costs",
                  name: "fundraising.campaigns.widgets.params.costs",
                  props: true,
                  component: () =>
                    import(
                      "/resources/js/ui/fundraising/components/campaigns/Item/Widgets/Params/Costs/Index.vue"
                    ),
                },
                {
                  path: "fields",
                  name: "fundraising.campaigns.widgets.params.fields",
                  props: true,
                  component: () =>
                    import(
                      "/resources/js/ui/fundraising/components/campaigns/Item/Widgets/Params/Fields/Index.vue"
                    ),
                },


              ],
            },
          ]
        },

      ],
    },
  ],
};
export default campaigns;
