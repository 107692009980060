export default [
    {
        path: 'json_markups',
        props: true,
        component: () => import('../components/json_markups/Index.vue'),
        children: [
            {
                path: '',
                name: 'settings.json_markups',
                component: () => import('../components/json_markups/List.vue'),
                props: true,
            },

        ]

    }
];
