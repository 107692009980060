export default [
    {
        path: 'text_block_types',
        props: true,
        component: () => import('../components/text_block_types/Index.vue'),
        children: [
            {
                path: '',
                name: 'settings.text_block_types',
                component: () => import('../components/text_block_types/List.vue'),
                props: true,
            },
            {
                path: 'create',
                name: 'settings.text_block_types.create',
                component: () => import('../components/text_block_types/Create.vue'),
            },
            {
                path: ':id',
                props: true,
                component: () => import('../components/text_block_types/Item.vue'),
                children: [
                    {
                        path: 'delete',
                        name: 'settings.text_block_types.delete',
                        props: true,
                        component: () => import('../components/text_block_types/Delete.vue'),
                    },
                    {
                        path: 'show',
                        name: 'settings.text_block_types.show',
                        props: true,
                        component: () => import('../components/text_block_types/Show.vue'),
                    },
                    {
                        path: 'update',
                        name: 'settings.text_block_types.update',
                        component: () => import('../components/text_block_types/Update.vue'),
                        props: true,
                    },
                ]
            },

        ]

    }
];
