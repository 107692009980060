export default [
    {
        path: 'posts',
        props: true,
        component: () => import('../components/Enter.vue'),
        name: 'content.posts.dashboard',
    },

    {
        path: 'posts/:label',
        component: () => import('../components/Index.vue'),
        props: true,
        children: [
            {
                path: '',
                name: 'content.posts.list',
                component: () => import('../components/List.vue'),
                props: true,
            },
            {

                path: ':id(\\d+)',
                props: true,
                component: () => import('../components/Item.vue'),
                children: [
                    {
                        path: 'delete',
                        name: 'content.posts.delete',
                        props: true,
                        component: () => import('../components/Delete.vue'),
                    },
                    {
                        path: 'show',
                        name: 'content.posts.show',
                        props: true,
                        component: () => import('../components/Show.vue'),
                    },
                    {
                        path: 'text_blocks',
                        name: 'content.posts.text_blocks',
                        props: true,
                        component: () => import('../components/TextBlocksEnter.vue'),
                    },
                    {
                        path: 'campaign',
                        name: 'content.posts.campaign',
                        props: true,
                        component: () => import('../components/Campaign.vue'),
                    },
                    {
                        path: 'media',
                        name: 'content.posts.media',
                        props: true,
                        component: () => import('../components/Media.vue'),
                    },
                    {
                        path: 'comments',
                        name: 'content.posts.comments',
                        props: true,
                        component: () => import('../components/Comments.vue'),
                    },
                    {
                        path: 'template',
                        name: 'content.posts.template',
                        props: true,
                        component: () => import('../components/Template.vue'),
                    },
                    {
                        path: 'schema_org',
                        name: 'content.posts.schema_org',
                        props: true,
                        component: () => import('../components/SchemaOrg.vue'),
                    },
                    {
                        path: 'meta_tags',
                        name: 'content.posts.meta_tags',
                        props: true,
                        component: () => import('../components/MetaTags.vue'),
                    },
                    {
                        path: 'update',
                        name: 'content.posts.update',
                        component: () => import('../components/Update.vue'),
                        props: true,
                    },
                ]
            },
        ]
    },




];
