
import posts from '../ui/posts/routes/index'
import tags from '../ui/tags/routes/index'
import text_blocks from '../ui/text_blocks/routes'
export default {
    path: 'content',
    component: () => import('../components/Index.vue'),
    name: 'content',
    children: [
        ...posts,
        ...text_blocks,
        ...tags,
    ]
}
