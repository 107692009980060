export default [
    {
        path: 'tags',
        component: () => import('../components/Index.vue'),
        name: 'content.tags',
        props: true,
        children: [
            {
                path: 'list',
                name: 'content.tags.list',
                component: () => import('../components/List.vue'),
                props: true,
            },
            {
                path: 'create',
                name: 'content.tags.create',
                props: true,
                component: () => import('../components/Create.vue'),
            },
            {

                path: ':id(\\d+)',
                props: true,
                component: () => import('../components/Item.vue'),
                children: [
                    {
                        path: 'update',
                        name: 'content.tags.update',
                        props: true,
                        component: () => import('../components/Update.vue'),
                    },
                    {
                        path: 'delete',
                        name: 'content.tags.delete',
                        props: true,
                        component: () => import('../components/Delete.vue'),
                    },
                    {
                        path: 'show',
                        name: 'content.tags.show',
                        props: true,
                        component: () => import('../components/Show.vue'),
                    },

                ]
            },
        ]
    },
];
