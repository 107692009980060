const donors = {
  path: "donors",
  name: "fundraising.donors",
  component: () =>
    import("/resources/js/ui/fundraising/components/donors/Index.vue"),
  props: true,
  children: [
    {
      path: "list",
      name: "fundraising.donors.list",
      component: () =>
        import("/resources/js/ui/fundraising/components/donors/List.vue"),
      props: true,
    },
    {
      path: ":id",
      props: true,
      component: () =>
        import("/resources/js/ui/fundraising/components/donors/Item.vue"),
      children: [
        {
          path: "delete",
          name: "fundraising.donors.delete",
          props: true,
          component: () =>
            import("/resources/js/ui/fundraising/components/donors/Delete.vue"),
        },
        {
          path: "show",
          name: "fundraising.donors.show",
          props: true,
          component: () =>
            import("/resources/js/ui/fundraising/components/donors/Show.vue"),
        },
        {
          path: "update",
          name: "fundraising.donors.update",
          props: true,
          component: () =>
            import("/resources/js/ui/fundraising/components/donors/Update.vue"),
        },
      ],
    },
  ],
};
export default donors;
