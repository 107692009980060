import model from "../../../store/modules/model";

const modules = [
    "campaigns",
    "gateways",
    "gateway_points",
    "payments",
    "donors",
    "sources",
    "pay_widgets",
    //
];
export function registerModulesFundraising(store) {
    modules.forEach((val) => {
        if (!store.hasModule(val)) {
            store.registerModule(val, model);
        }
        store.commit(`${val}/api`, `fundraising/` + val);
        store.commit(`${val}/name`, val);
    });
}
export default registerModulesFundraising;