<template>
    <div>
        <!-- Кнопка открытия диалога -->
        <v-btn color="primary" elevation="2" small @click="dialog = true" :loading="loading" class="improve-btn">
            <v-icon left>auto_fix_high</v-icon>
            <span class="text-caption text-uppercase">AI Улучшение</span>
        </v-btn>

        <!-- Диалоговое окно -->
        <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition">
            <v-card class="improve-dialog d-flex flex-column">
                <!-- Заголовок -->
                <v-app-bar dark color="primary" class="toolbar-header" elevation="0" dense>
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <small class=" font-weight-medium text-caption text-uppercase">AI Улучшение</small>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mr-2" small @click="saveText" :disabled="loading">
                        <v-icon left small>check</v-icon>
                        Применить
                    </v-btn>
                    <v-btn color="primary" small @click="improveText" :loading="loading" :disabled="loading">
                        <v-icon left small>auto_fix_high</v-icon>
                        Улучшить
                    </v-btn>
                </v-app-bar>

                <v-container fluid class="grey lighten-4 flex-grow-1 pa-6">
                    <!-- Форма настроек -->
                    <v-card class="mb-6" elevation="0">
                        <v-card-text class="pa-4">
                            <v-row align="center">
                                <v-col cols="4">
                                    <v-select v-model="selectedModel" :items="models" item-text="name" item-value="id"
                                        label="Модель AI" required dense filled hide-details class="model-select"
                                        prepend-inner-icon="psychology"></v-select>
                                </v-col>
                                <v-col cols="4">
                                    <v-select v-model="selectedPrompt" :items="prompts" item-text="name" item-value="id"
                                        label="Шаблон" required dense filled hide-details @change="onPromptChange"
                                        prepend-inner-icon="description">
                                        <template v-slot:item="{ item }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                                                </template>
                                                <span>{{ item.description }}</span>
                                            </v-tooltip>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="2">
                                    <v-slider v-model="temperature" label="Креативность" min="0" max="1" step="0.1"
                                        thumb-label="always" hide-details class="mt-0 pt-0"></v-slider>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model.number="maxTokens" label="Токены" type="number" min="1000"
                                        max="16000" step="1000" dense filled hide-details
                                        prepend-inner-icon="numbers"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <!-- Редакторы -->
                    <v-row class="flex-grow-1">
                        <v-col cols="6" class="d-flex">
                            <v-card class="card-content" elevation="2">
                                <v-toolbar dense flat color="grey lighten-3" class="border-bottom">
                                    <v-icon left small color="grey darken-2">material-icons-outlined
                                        description</v-icon>
                                    <span class="subtitle-2 grey--text text--darken-2">Оригинал</span>
                                </v-toolbar>
                                <v-card-text class=" pa-4">
                                    <div v-html="originalText" class="content-text"></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="d-flex">
                            <v-card class="card-content" elevation="2">
                                <v-toolbar dense flat color="success lighten-5" class="border-bottom">
                                    <v-icon left small color="success">edit_note</v-icon>
                                    <span class="subtitle-2 success--text">Результат</span>
                                </v-toolbar>
                                <v-card-text class=" pa-4">
                                    <div v-html="improvedText" class="content-text"></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { getMsgError, useToast } from '@utils';
import axios from 'axios';
import { computed, onMounted, ref, watch } from 'vue';

export default {
    name: 'ImproveTextDialog',

    props: {

        originalText: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    setup(props, { emit }) {
        const toast = useToast();

        // Состояние
        const dialog = ref(false);
        const loading = ref(false);
        const models = ref([]);
        const selectedModel = ref(null);
        const temperature = ref(0.7);
        const maxTokens = ref(8000);
        const originalText = computed(() => props.originalText);
        const improvedText = ref('');
        const prompts = ref([]);
        const selectedPrompt = ref(null);


        watch(originalText, (newVal) => {
            improvedText.value = newVal;
        });
        // Методы
        const loadModels = async () => {
            try {
                loading.value = true;
                const response = await axios.get('ai-processor/models/gpt');
                models.value = JSON.parse(JSON.stringify(response.data.items));
                if (models.value.length > 0) {
                    selectedModel.value = models.value.find(m => m.is_default)?.id || models.value[0].id;
                }
            } catch (error) {
                toast.error(getMsgError(error));
            } finally {
                loading.value = false;
            }
        };

        const loadPrompts = async () => {
            try {
                loading.value = true;
                const response = await axios.get('ai-processor/prompts/improve/html');
                prompts.value = JSON.parse(JSON.stringify(response.data.items));
                if (prompts.value.length > 0) {
                    selectedPrompt.value = prompts.value.find(p => p.is_default)?.id || prompts.value[0].id;
                    onPromptChange(selectedPrompt.value);
                }
            } catch (error) {
                toast.error(getMsgError(error));
            } finally {
                loading.value = false;
            }
        };

        const onPromptChange = (promptId) => {
            const prompt = prompts.value.find(p => p.id === promptId);
            if (prompt) {
                temperature.value = prompt.temperature;
                maxTokens.value = prompt.max_tokens;
            }
        };

        const improveText = async () => {
            if (!selectedModel.value || !selectedPrompt.value || !originalText.value) {
                toast.warning('Выберите модель, шаблон и введите текст');
                return;
            }

            loading.value = true;
            try {
                const response = await axios.post('/ai-processor/improve/html',
                    {
                        model_id: selectedModel.value,
                        prompt_id: selectedPrompt.value,
                        text: originalText.value,
                        temperature: temperature.value,
                        max_tokens: maxTokens.value
                    });


                improvedText.value = response.data.text;
                emit('improved', improvedText.value);
                toast.success('Текст успешно улучшен');
            } catch (error) {
                toast.error(getMsgError(error));
            } finally {
                loading.value = false;
            }
        };


        const saveText = async () => {
            if (!improvedText.value) {
                toast.warning('Выберите модель, шаблон и введите текст');
                return;
            }
            emit('save', improvedText.value);
            dialog.value = false;
        };




        // Хуки жизненного цикла
        onMounted(() => {
            improvedText.value = originalText.value;
            loadModels();
            loadPrompts();
        });

        // Возвращаем все, что нужно использовать в шаблоне
        return {
            dialog,
            loading,
            models,
            selectedModel,
            temperature,
            maxTokens,
            originalText,
            improvedText,
            improveText,
            prompts,
            selectedPrompt,
            onPromptChange,
            saveText
        };
    }
}
</script>

<style scoped>
.improve-btn {
    text-transform: none;
    border-radius: 8px;
    font-weight: 500;
}

.improve-dialog {
    background-color: var(--v-grey-lighten4);
}

.toolbar-header {
    border: none;
}

.border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}



.content-text {
    font-size: 14px;
    line-height: 1.6;
    color: #2c3e50;
}

:deep(.v-text-field__details),
:deep(.v-messages) {
    display: none;
}

:deep(.v-input__prepend-inner) {
    margin-right: 8px !important;
    margin-top: 0 !important;
}

:deep(.v-input__slot) {
    min-height: 40px !important;
    box-shadow: none !important;
}

:deep(.v-slider__thumb-label) {
    background-color: var(--v-primary-base) !important;
}


/* Анимации */
.v-btn {
    transition: transform 0.2s ease;
}

.v-btn:not(:disabled):hover {
    transform: translateY(-1px);
}

/* Тени для карточек */
.v-card {
    transition: box-shadow 0.2s ease;
}

.v-card:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
}
</style>