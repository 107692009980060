import campaigns from "./campaigns.js";
import donors from "./donors.js";
import payments from "./payments.js";
import settings from "./settings/index.js";

export default [
  {
    path: "fundraising",
    component: () => import("/resources/js/ui/fundraising/components/Index.vue"),
    name: "fundraising",
    children: [
      {
        path: "dashboard",
        name: "fundraising.dashboard",
        component: () =>
          import("/resources/js/ui/fundraising/components/dashboard/Index.vue"),
        props: true,
      },
      campaigns,
      donors,
      payments,
      settings,
      {
        path: "tools",
        name: "fundraising.tools",
        component: () =>
          import("/resources/js/ui/fundraising/components/settings/Index.vue"),
        props: true,
        children: [],
      },
      {
        path: "analytics",
        name: "fundraising.analytics",
        component: () =>
          import("/resources/js/ui/fundraising/components/analytics/Index.vue"),
        props: true,
        children: [],
      },
    ],
  },
];
